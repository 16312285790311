import React, {useCallback, useEffect, useMemo, useState} from 'react';
import FixedRatioImage from '../Components/FixedRatioImage';
import {Actions, Breakpoints} from '../Contexts/AppContext';
import styled from 'styled-components';
import RefineBar from '../Components/RefineBar';
import useFetchProducts from '../Hooks/useFetchProducts';
import ProductGrid from '../Components/ProductGrid';
import useProductGridProps from '../Hooks/useProductGridProps';
import * as L from '../Utils/Lang';
import queryString from 'query-string';
import * as Widget from '../Components/Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import {useDimension} from '../Hooks/AppHooks';
import CustomNav from '../Components/CustomNav';
import {MiniCartIcon} from '../Components/MiniCartIcon';

const EVENT_TYPE = {
  promotion: 'promotion',
  target_price_discount: 'target_price_discount',
};

const EventPage = props => {
  const {location} = props;
  const {id} = queryString.parse(location.search);
  const {mobile, tablet} = useDimension();
  const {itemPerRow, itemWidth} = useProductGridProps();
  const [event, setEvent] = useState(null);

  const fetchEvent = useCallback(async () => {
    try {
      setEvent(await Actions.fetchEvent(id));
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  const categoryIds = useMemo(
    () =>
      event && event.promotion
        ? event.promotion.categories.map(category => category.id.toString())
        : [],
    [event],
  );

  const collectionIds = useMemo(
    () =>
      event && event.promotion
        ? event.promotion.collections.map(collection =>
            collection.id.toString(),
          )
        : [],
    [event],
  );

  const subCollectionIds = useMemo(
    () =>
      event && event.promotion
        ? event.promotion.sub_collections.map(subCollection =>
            subCollection.id.toString(),
          )
        : [],
    [event],
  );

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  const {
    updateFilter,
    updateSort,
    loadNextPage,
    selectedFilterCnt,
    totalCount,
    sort,
    filterOptions,
    products,
  } = useFetchProducts({
    brand: null,
    categoryIds,
    collectionIds,
    subCollectionIds,
    isFetch: event?.promotion,
  });

  if (!event) {
    return null;
  }

  return (
    <Wrapper smallDevice={mobile || tablet}>
      {(tablet || mobile) && <CustomNav right={<MiniCartIcon />} />}
      <div data-nav-type="revert">
        <FixedRatioImage
          image={event.image.original}
          ratio={540 / 1440}
          width="100%"
          mode="cover"
          rwd={[
            {
              ratio: 480 / 375,
              breakpoint: Breakpoints.mobile,
              image: event.image.mobile ?? event.image.original,
            },
          ]}
        />
      </div>
      <BrandInfo data-nav-type="solid">
        <h2>{L.d(event.title)}</h2>
        <p>{L.d(event.description)}</p>
      </BrandInfo>
      {event.event_type === EVENT_TYPE.promotion && (
        <div className="product-area">
          <RefineBar
            mobile={mobile}
            filter={filterOptions}
            sort={sort}
            sortOptions={['+price', '-price', '-created']}
            onUpdateFilter={updateFilter}
            onUpdateSort={updateSort}
            selectedFilterCnt={selectedFilterCnt}
            style={{margin: '56px 0 48px 0'}}
          />

          {products.length !== 0 && (
            <ProductGrid
              products={products}
              itemPerRow={itemPerRow}
              itemWidth={itemWidth}
            />
          )}

          {products.length < totalCount && (
            <Widget.FlexCenter style={{padding: 20}}>
              <RectButton
                skin={BUTTON_SKIN.DEFAULT}
                size={mobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
                style={{margin: '0 auto', width: '160px'}}
                onClick={loadNextPage}
                text="更多商品"
              />
            </Widget.FlexCenter>
          )}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .product-area {
    margin: 0 auto;
    max-width: 1440px;
    padding: ${props => (props.smallDevice ? '0 20px' : '0 50px')};
  }
`;

const BrandInfo = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 48px 20px 20px 20px;
  text-align: center;

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  & > p {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default EventPage;
